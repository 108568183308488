import { Component, Fragment, ReactNode } from 'react';

import { css } from '@emotion/react';
import { Link } from 'gatsby';
import { MerchantForList, DiscountCreateEditFormType, NavPages } from 'global.types';
import { observe } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Box, Flex } from 'rebass';
import * as Yup from 'yup';

import {
  Discount,
  ProductType,
  ProductCategory,
  Channel
} from 'generated-types.d';
import { SupportedISOString } from 'global-types';

import {
  ValidationService,
  CurrencyService,
  PermissionsService,
  NavService,
  Auth
} from 'lib';

import { DiscountFormSchema, DiscountType, DiscountApplication } from 'stores/discount-create-edit/discount-create-edit-store.types';
import { ToastType } from 'stores/toaster-store/toaster-store.types';

import { Container } from 'utils/css-mixins';
import { colors } from 'utils/rebass-theme';

import { ConfirmationModalData } from 'features/modal-dialogue/components/modals/confirmation-modal/confirmation-modal.types';
import { TagSelectionModalData } from 'features/modal-dialogue/components/modals/tag-selection-modal/tag-selection-modal.types';
import DiscountService from 'features/settings/components/discounts/services';

import Button from 'components/button';
import FormBuilder from 'components/form-builder';
import { FormBuilderConfig } from 'components/form-builder/form-builder.types';
import FormFooter from 'components/form-footer';
import FormHeader from 'components/form-header';
import NoResultsGeneric from 'components/no-results-generic';
import { NotificationType } from 'components/notification/notification.types';
import WithLoading from 'components/with-loading';

import * as Constants from './discount-create-edit-layout.constants';
import * as Types from './discount-create-edit-layout.types';

class DiscountCreateEditLayout extends Component<Types.DiscountCreateEditLayoutProps, Types.DiscountCreateEditLayoutState> {
  private renderCodePlaceholder = (): string => {
    const placeholderOptions = ['FLOWERS10', '15OFF', 'SHIPFREE', 'VDAY10', 'SAVE10', 'SUMMERSALE', `${moment().format('MMM').toUpperCase()}10`];
    const randomNumber = Math.floor(Math.random() * placeholderOptions.length);

    return placeholderOptions[randomNumber];
  };

  private isValidating: boolean = false;

  private codePlaceholder: string = this.renderCodePlaceholder();

  private merchantObserver = observe(this.props.merchantStore!, 'merchant', ({ oldValue, newValue }: any) => {
    if (oldValue !== newValue && !!newValue) {
      this.setState({ selectedMerchant: newValue }, () => this.fetchProducts());
    }
  });

  private discountObserver = observe(this.props.discountCreateEditStore!, 'importedDiscount', ({ oldValue, newValue }: any) => {
    if (oldValue === null && !!newValue.id) {
      const discount = newValue as Discount;

      DiscountService.fetchProducts(
        this.state.productSearchValue.toLowerCase().trim(),
        discount.merchant?.id
      );
    }
  });

  private formValidationObserver = observe(this.props.discountCreateEditStore!, 'formValidationErrors', ({ oldValue, newValue }: any) => {
    if (oldValue !== newValue && !!newValue && this.isValidating && !!newValue.length) {
      setTimeout(() => {
        this.isValidating = false;
        const items = document.getElementsByClassName('form-builder-section--has-error') as HTMLCollectionOf<HTMLElement>;

        if (!!items.length) {
          this.scrollTo(items[0].offsetTop - 70);
        }

        this.props.toasterStore?.popToast(
          'Oops, there are are few issues with your discount. Check the errors you can see and try again!',
          ToastType.Error
        );
      }, 300);
    }
  });

  state: Types.DiscountCreateEditLayoutState = {
    isPosting: false,
    isDirty: false,
    isGeneratingCode: false,
    errorNotification: '',
    selectedMerchant: this.props.merchantStore!.merchant,
    productSearchValue: ''
  };

  validation: Yup.Schema<any> = Yup.object().shape({
    code: Yup
      .string()
      .required(params => this.schemaError(params, 'Please enter a discount code'))
      .matches(/^[a-zA-Z0-9]+$/, params => this.schemaError(params, 'Oops! discount codes can only include letters and numbers, with no spaces')),
    description: Yup.string().required(params => this.schemaError(params, 'Please add a useful description of the discount so you know what it\'s for')),
    startsAt: Yup.string().nullable().notRequired(),
    endsAt: Yup.string().nullable().notRequired(),
    isActive: Yup.boolean().required(),
    usageLimit: Yup.string().notRequired(),
    perUserLimit: Yup.boolean().required(),
    discountType: Yup.string().required(),
    amount: Yup.string().test('amountValidator', 'Amount error', function(value: string): boolean | Yup.ValidationError {
      // Yup.number doesn't validate empty values, so we've used string and then parse the string to an int
      const valueAsInt = parseInt(value);
      const path = this.path;
      const discountTypeValue = this.resolve(Yup.ref('discountType'));

      switch (true) {
        case discountTypeValue === 'Fixed':
          if (!valueAsInt || valueAsInt === 0) {
            return this.createError({
              path: path,
              // @ts-ignore
              message: {
                path: path,
                label: 'Discount amount must be 1 or more'
              }
            });
          }

          break;

        case discountTypeValue === 'Percent':
          if (!valueAsInt || valueAsInt === 0 || valueAsInt > 100) {
            return this.createError({
              path: path,
              // @ts-ignore
              message: {
                path: path,
                label: 'Percentage must be between 1 and 100'
              }
            });
          }

          break;
      }

      return true;
    }),
    minBasketValue: Yup.string().notRequired(),
    appliesTo: Yup.string().required(),
    selectedProducts: Yup.array().of(Yup.object().shape({
      id: Yup.string().required()
    })).when('appliesTo', {
      is: 'Products',
      then: Yup.array().required(params => this.schemaError(params, 'Please select at least one product'))
    }),
    productTypes: Yup.array().of(Yup.object().shape({
      id: Yup.string().required()
    })).when('appliesTo', {
      is: 'ProductTypes',
      then: Yup.array().required(params => this.schemaError(params, 'Please select at least one product type'))
    }),
    productCategories: Yup.array().of(Yup.object().shape({
      id: Yup.string().required()
    })).when('appliesTo', {
      is: 'ProductCategories',
      then: Yup.array().required(params => this.schemaError(params, 'Please select at least one occasion'))
    }),
    oncePerOrder: Yup.boolean().required()
  });

  componentDidMount = (): void => {
    DiscountService.fetchDiscountProductMetadata();
    this.fetchProducts();
  };

  /**
   * @description - Clears observers, and resets store
   */
  componentWillUnmount = (): void => {
    this.merchantObserver();
    this.formValidationObserver();
    this.discountObserver();

    this.props.discountCreateEditStore!.resetStore();
  };

  private isDiscountType = (type: DiscountType): boolean => {
    return this.props.discountCreateEditStore!.formValues.discountType === type;
  };

  private isProductTypeSelected = (type: ProductType): boolean => {
    return this.props.discountCreateEditStore!.formValues.productTypes.some(selectedType => selectedType.id === type.id);
  };

  private isProductCategorySelected = (category: ProductCategory): boolean => {
    return this.props.discountCreateEditStore!.formValues.productCategories.some(selectedCategory => selectedCategory.id === category.id);
  };

  private hasNoMerchantOnCreate = (): boolean => {
    return !this.state.selectedMerchant && this.props.formType === 'create';
  };

  private isLoadingOnEdit = (): boolean => {
    return this.props.discountCreateEditStore!.isLoadingDiscount && this.props.formType === 'edit';
  };

  private cannotFindDiscountToEdit = (): boolean => {
    return !this.props.discountCreateEditStore!.isLoadingDiscount
      && !this.props.discountCreateEditStore!.importedDiscount
      && this.props.formType === 'edit';
  };

  private fetchProducts = async (): Promise<void> => {
    await DiscountService.fetchProducts(
      this.state.productSearchValue.toLowerCase().trim(),
      this.state.selectedMerchant?.id
    );
  };

  private findError = (key: keyof DiscountFormSchema): Yup.TestMessageParams | undefined => {
    return ValidationService.findErrorCopy(this.props.discountCreateEditStore!.formValidationErrors, key);
  };

  private schemaError = (params: Partial<Yup.TestMessageParams>, errorCopy: string): Partial<Yup.TestMessageParams> => {
    return ValidationService.generateYupMessageSchema(params, errorCopy);
  };

  private scrollTo = (top: number): void => {
    window?.scrollTo({
      top: top,
      behavior: 'smooth'
    });
  };

  private makeItDirty = (): void => {
    if (!this.state.isDirty) {
      this.setState({
        isDirty: true
      });
    }
  };

  private handleChange = (value: DiscountFormSchema[keyof DiscountFormSchema], key: keyof DiscountFormSchema): void => {
    this.makeItDirty();
    this.props.discountCreateEditStore!.setValue(value, key);
  };

  private handleSubmit = async (): Promise<void> => {
    this.isValidating = true;
    this.setState({ isPosting: true });

    const submitConfig: { [key in DiscountCreateEditFormType]: () => Promise<void> } = {
      create: this.handleDiscountCreate,
      edit: this.handleDiscountUpdate
    };

    try {
      await ValidationService.validateAll(
        this.validation,
        this.props.discountCreateEditStore!.formValues
      );

      await submitConfig[this.props.formType]();
    } catch (error: any) {
      this.setState({ isPosting: false });
      this.props.discountCreateEditStore!.setErrors(error.errors);
    }
  };

  private handleDiscountUpdate = async (): Promise<void> => {
    if (this.state.isDirty) {
      this.setState({ errorNotification: '' });

      try {
        const discount = await DiscountService.updateDiscount();

        if (discount) {
          this.setState({ isPosting: false });
          this.props.toasterStore!.popSuccessToast(`discount "${discount.code}"`, 'update');
          NavService.discountsList();
        }
      } catch (error) {
        this.props.toasterStore?.popToast('We\'re having trouble creating the discount. We\'ve provided some more information for you', ToastType.Error);

        this.setState({
          isPosting: false,
          errorNotification: this.handleAPIValidation(error)
        });
      }
    }
  };

  private handleDiscountCreate = async (): Promise<void> => {
    if (this.state.isDirty && this.state.selectedMerchant!.id) {
      this.setState({ errorNotification: '' });

      try {
        const discount = await DiscountService.createDiscount(this.state.selectedMerchant!.id);

        if (discount) {
          this.setState({ isPosting: false });
          this.handleListNotification(discount);
          this.props.toasterStore!.popSuccessToast(`discount "${discount.code}"`, 'create');
          NavService.discountsList();
        }
      } catch (error) {
        this.props.toasterStore?.popToast('We\'re having trouble creating the discount. We\'ve provided some more information for you', ToastType.Error);

        this.setState({
          isPosting: false,
          errorNotification: this.handleAPIValidation(error)
        });
      }
    }
  };

  private handleAPIValidation = (error: any): string => {
    if (error?.graphQLErrors?.length) {
      switch (error?.graphQLErrors[0]?.code) {
        case 77945739587:
          const merchantCopy = PermissionsService.isInternalRole()
            ? `${this.state.selectedMerchant?.title} already has`
            : 'You already have';

          this.props.discountCreateEditStore!.setError({
            label: `${merchantCopy} a discount code called ${this.props.discountCreateEditStore!.formValues.code}.`,
            path: 'code',
            originalValue: this.props.discountCreateEditStore!.formValues.code,
            value: this.props.discountCreateEditStore!.formValues.code
          });

          return '';

        default:
          return '';
      }
    }

    return '';
  };

  private handleListNotification = (discount: Discount): void => {
    const globalMerchantID = Auth.getMerchantIdCookie();

    switch (true) {
      case globalMerchantID !== this.state.selectedMerchant?.id && PermissionsService.isInternalRole():
        const hiddenReason = !globalMerchantID ? 'you do not have a merchant selected globally' : 'you have a different merchant selected';

        this.props.discountsStore!.setNotification(
          `The discount "${this.props.discountCreateEditStore!.formValues.code}" was successfully created, but may not be visible because ${hiddenReason}. To view the new discount, select "${this.state.selectedMerchant?.title}" in the left hand nav.`,
          NotificationType.Progress
        );

        break;

      case !discount.isActive:
        this.props.discountsStore!.setNotification(
          `Your discount "${this.props.discountCreateEditStore!.formValues.code}" was successfully created, but is not yet enabled. To activate it, make sure to set it to active in the list below!`,
          NotificationType.Progress
        );

        break;
    }
  };

  private handleReset = async ({
    onConfirm,
    onModalConfirm
  }: {
    onModalConfirm: () => Promise<void>;
    onConfirm: () => Promise<void>;
  }): Promise<void> => {
    if (this.state.isDirty) {
      this.props.modalStore!.triggerModal<ConfirmationModalData>({
        modalType: 'confirmation',
        data: {
          title: 'Confirm reset form',
          copy: 'Are you sure you want to make this change? Warning: this will clear your current progress',
          confirmButtonCopy: 'Yes, change',
          cancelButtonCopy: 'No, go back',
          errorCopy: '',
          confirmButtonAction: async () => new Promise(async (resolve): Promise<void> => {
            this.isValidating = true;
            this.props.discountCreateEditStore!.resetCreate();
            await onModalConfirm();
            this.resetCreationState();
            resolve();
          })
        }
      });
    } else {
      this.props.discountCreateEditStore!.resetCreate();
      await onConfirm();
      this.resetCreationState();
    }
  };

  private resetCreationState = (): void => {
    this.setState(() => ({
      isPosting: false,
      isDirty: false,
      isGeneratingCode: false,
      errorNotification: ''
    }));
  }

  private selectMerchant = (merchantID: string): void => {
    const selectedMerchant = this.props.merchantStore!.merchantList.find(merchant => merchant.id === merchantID) as MerchantForList;

    this.setState(() => ({
      selectedMerchant: selectedMerchant || null
    }), () => {
      if (selectedMerchant) {
        this.fetchProducts();
      } else {
        this.props.discountCreateEditStore!.resetSelectableProducts();
      }
    });
  };

  private handleActiveStateSelect = (): void => {
    this.handleChange(!this.props.discountCreateEditStore!.formValues.isActive, 'isActive');
  };

  private buildProductTypeModalData = (): TagSelectionModalData => {
    return {
      title: 'Add product types',
      confirmButtonCopy: 'Add product types',
      limitSelection: undefined,
      items: this.props.discountCreateEditStore!.productTypes.map(type => ({
        title: type.title!,
        id: type.id,
        isSelected: this.isProductTypeSelected(type)
      })),
      confirmButtonAction: (items): void => {
        this.makeItDirty();

        const selectedItems = items.filter(item => item.isSelected).map(item => item.id);

        this.props.discountCreateEditStore!.applyProductMetadataIDs(selectedItems, 'productTypes', 'productTypes');

        if (!selectedItems.length) {
          this.props.discountCreateEditStore!.removeValidationError('productCategories');
        }
      }
    };
  };

  private buildProductCategoryModalData = (): TagSelectionModalData => {
    return {
      title: 'Add product occasions',
      confirmButtonCopy: 'Add occasions',
      limitSelection: undefined,
      items: this.props.discountCreateEditStore!.productCategories.map(category => ({
        title: category.title!,
        id: category.id,
        isSelected: this.isProductCategorySelected(category)
      })),
      confirmButtonAction: (items): void => {
        this.makeItDirty();

        const selectedItems = items.filter(item => item.isSelected).map(item => item.id);

        this.props.discountCreateEditStore!.applyProductMetadataIDs(selectedItems, 'productCategories', 'productCategories');

        if (!selectedItems.length) {
          this.props.discountCreateEditStore!.removeValidationError('productTypes');
        }
      }
    };
  };

  private triggerProductTypeModal = (data: TagSelectionModalData): void => {
    this.props.modalStore!.triggerModal({
      modalType: 'tagSelection',
      data: data
    });
  };

  private toggleGeneratingCodeState = (): void => {
    this.setState({ isGeneratingCode: true }, () => {
      setTimeout(() => {
        this.setState({ isGeneratingCode: false });
      }, 1500);
    });
  };

  private renderMerchantCurrencySymbol = (): string => {
    return this.state.selectedMerchant ? CurrencyService.renderCurrencySymbol(this.state.selectedMerchant.currency as SupportedISOString) : '';
  };

  private renderDiscountValuePrefix = (): string => {
    switch (this.props.discountCreateEditStore!.formValues.discountType) {
      case DiscountType.Fixed:
      case DiscountType.FreeShipping:
        return this.renderMerchantCurrencySymbol();

      case DiscountType.Percent:
        return '%';
    }
  };

  private renderDiscountAmount = (): string => {
    const amount = this.props.discountCreateEditStore?.formValues.amount;

    switch (true) {
      case this.isDiscountType(DiscountType.Percent): {
        return `${amount}%`;
      }

      case !!this.state.selectedMerchant: {
        return CurrencyService.formatPrice(!amount ? 0 : parseFloat(amount!), this.state.selectedMerchant!.currency as SupportedISOString);
      }

      default: {
        return '';
      }
    }
  };

  private switchCopy = (createCopy: string, editCopy: string): string => {
    return this.props.formType === 'create' ? createCopy : editCopy;
  };

  private appliesToSubCopy = (): string => {
    switch (this.props.discountCreateEditStore!.formValues.appliesTo) {
      case DiscountApplication.EntireOrder:
        return 'all products';

      case DiscountApplication.ProductCategories:
        return 'products with specific occasions';

      case DiscountApplication.ProductTypes:
        return 'products with specific product types';

      case DiscountApplication.Products:
        return 'specific products only';
    }
  };

  private buildForm = (): FormBuilderConfig => {
    const hasNoMerchantOnCreate = this.hasNoMerchantOnCreate();
    const isFloomDiscount = this.props.discountCreateEditStore!.formValues!.channel === Channel.Floom;
    const isFreeShippingSelected = this.isDiscountType(DiscountType.FreeShipping);
    const isPercentageSelected = this.isDiscountType(DiscountType.Percent);

    return {
      sections: [
        {
          width: '100',
          isHidden: !PermissionsService.isInternalRole() || this.props.formType === 'edit',
          fields: [
            {
              key: 'discountChannelLabel',
              fieldType: 'label',
              copy: 'Channel',
              validationError: undefined
            },
            {
              key: 'discountChannelField',
              fieldType: 'radioList',
              itemType: 'default',
              itemTitleField: 'title',
              itemValueField: 'value',
              orientation: 'horizontal',
              items: Constants.DISCOUNT_CHANNELS,
              selectedItem: this.props.discountCreateEditStore!.formValues.channel,
              validationError: this.findError('channel'),
              onChange: (value: string) => {
                const handleConfirm = async (): Promise<void> => {
                  this.selectMerchant('');
                  this.handleChange(value, 'channel');
                };

                this.handleReset({
                  onModalConfirm: handleConfirm,
                  onConfirm: handleConfirm
                });
              }
            }
          ]
        },
        {
          width: '100',
          isHidden: !PermissionsService.isInternalRole() || this.props.formType === 'edit' || this.props.discountCreateEditStore!.formValues.channel === Channel.Floom,
          fields: [
            {
              key: 'selectMerchantLabel',
              fieldType: 'label',
              copy: 'Who are you creating the discount for?',
              subCopy: !this.state.selectedMerchant ? 'It\'s needed before you can create a discount for them' : '',
              validationError: undefined
            },
            {
              key: 'selectMerchantField',
              fieldType: 'dropdown',
              id: 'discount-create-edit-merchant-select',
              placeholder: 'Select a merchant',
              selected: this.state.selectedMerchant?.id,
              options: this.props.merchantStore!.merchantList,
              optionTitleField: 'title',
              optionValueField: 'id',
              fullWidth: true,
              onChange: (value: string) => this.handleReset({
                onModalConfirm: async () => {
                  this.selectMerchant(value);
                },
                onConfirm: async () => {
                  this.selectMerchant(value);
                }
              })
            }
          ]
        },
        {
          width: '100',
          isHidden: hasNoMerchantOnCreate && !isFloomDiscount,
          fields: [
            {
              key: 'discountCodeLabel',
              fieldType: 'label',
              copy: 'Discount code',
              validationError: undefined,
              actionCopy: this.state.isGeneratingCode ? 'Code generated!' : 'Generate code',
              actionColour: this.state.isGeneratingCode ? colors.validationText : colors.floomMidnightBlue,
              isActionDisabled: !!this.props.discountCreateEditStore!.formValues.code.length,
              onClickAction: (): void => {
                this.makeItDirty();
                this.props.discountCreateEditStore!.generateCode();
                this.toggleGeneratingCodeState();
              }
            },
            {
              key: 'discountCodeField',
              fieldType: 'textInput',
              placeholder: `e.g. ${this.codePlaceholder}`,
              maxLength: 20,
              displayMaxLength: true,
              value: this.props.discountCreateEditStore!.formValues.code,
              validationError: this.findError('code'),
              onChange: (e): void => this.handleChange(e.target.value.trim().toUpperCase(), 'code')
            }
          ]
        },
        {
          width: '100',
          isHidden: hasNoMerchantOnCreate && !isFloomDiscount,
          fields: [
            {
              key: 'descriptionLabel',
              fieldType: 'label',
              copy: 'Description',
              subCopy: 'Internal only',
              validationError: undefined
            },
            {
              key: 'descriptionField',
              fieldType: 'textInput',
              maxLength: 70,
              displayMaxLength: true,
              value: this.props.discountCreateEditStore!.formValues.description,
              validationError: this.findError('description'),
              onChange: (e): void => this.handleChange(e.target.value, 'description')
            }
          ]
        },
        {
          width: '50',
          isHidden: hasNoMerchantOnCreate && !isFloomDiscount,
          fields: [
            {
              key: 'startDateLabel',
              fieldType: 'label',
              copy: 'Valid from',
              isOptional: true,
              validationError: undefined
            },
            {
              key: 'startDateField',
              fieldType: 'datePicker',
              format: 'L',
              pickerType: 'tooltip',
              inputType: 'input',
              isClearable: true,
              value: this.props.discountCreateEditStore!.formValues.startsAt || '',
              validationError: this.findError('startsAt'),
              onChange: (day): void => this.handleChange(moment(day).toISOString(), 'startsAt'),
              onClear: (): void => this.handleChange('', 'startsAt'),
              disabledDays: {
                before: new Date(),
                after: new Date(this.props.discountCreateEditStore!.formValues.endsAt || '')
              }
            }
          ]
        },
        {
          width: '50',
          isHidden: hasNoMerchantOnCreate && !isFloomDiscount,
          fields: [
            {
              key: 'endDateLabel',
              fieldType: 'label',
              copy: 'Valid until',
              isOptional: true,
              validationError: undefined
            },
            {
              key: 'endDateField',
              fieldType: 'datePicker',
              format: 'L',
              pickerType: 'tooltip',
              inputType: 'input',
              isClearable: true,
              value: this.props.discountCreateEditStore!.formValues.endsAt || '',
              validationError: this.findError('endsAt'),
              onChange: (day): void => this.handleChange(moment(day).toISOString(), 'endsAt'),
              onClear: (): void => this.handleChange('', 'endsAt'),
              disabledDays: {
                before: new Date(this.props.discountCreateEditStore!.formValues.startsAt || '')
              }
            }
          ]
        },
        {
          width: '50',
          isHidden: hasNoMerchantOnCreate && !isFloomDiscount,
          fields: [
            {
              key: 'totalUseCountLabel',
              fieldType: 'label',
              copy: 'Total use count',
              isOptional: true,
              validationError: undefined
            },
            {
              key: 'totalUseCountField',
              fieldType: 'textInput',
              type: 'number',
              value: this.props.discountCreateEditStore!.formValues.usageLimit || '',
              validationError: this.findError('usageLimit'),
              onChange: (e): void => this.handleChange(e.target.value, 'usageLimit')
            }
          ]
        },
        {
          width: '50',
          isHidden: hasNoMerchantOnCreate && !isFloomDiscount,
          fields: [
            {
              key: 'oncePerCustomerField',
              fieldType: 'singleCheckbox',
              label: 'Only one use per customer email',
              isSelected: this.props.discountCreateEditStore!.formValues.perUserLimit,
              isDisabled: false,
              validationError: this.findError('perUserLimit'),
              handleSelect: (): void => this.handleChange(!this.props.discountCreateEditStore!.formValues.perUserLimit, 'perUserLimit'),
              customStyles: css`
                margin: 55px 0 0 15px;
              `
            }
          ]
        },
        {
          width: '100',
          isHidden: hasNoMerchantOnCreate && !isFloomDiscount,
          fields: [
            {
              key: 'discountTypeLabel',
              fieldType: 'label',
              copy: 'Discount type',
              validationError: undefined
            },
            {
              key: 'discountTypeField',
              fieldType: 'radioList',
              itemType: 'default',
              itemTitleField: 'title',
              itemValueField: 'value',
              orientation: 'horizontal',
              items: Constants.DISCOUNT_TYPES,
              selectedItem: this.props.discountCreateEditStore!.formValues.discountType,
              validationError: this.findError('discountType'),
              onChange: (type: string): (void) => {
                this.handleChange('', 'amount');
                this.handleChange(type, 'discountType');
              }
            }
          ]
        },
        {
          width: '50',
          isHidden: hasNoMerchantOnCreate && !isFloomDiscount,
          fields: [
            {
              key: 'discountValueLabel',
              fieldType: 'label',
              copy: 'Discount value',
              subCopy: 'The amount to be discounted',
              validationError: undefined
            },
            {
              key: 'discountValueField',
              fieldType: 'textInput',
              type: 'number',
              min: 0,
              step: '0.01',
              prefix: this.renderDiscountValuePrefix(),
              value: this.props.discountCreateEditStore!.formValues.amount,
              isDisabled: this.isDiscountType(DiscountType.FreeShipping),
              validationError: this.findError('amount'),
              onChange: (e): void => this.handleChange(e.target.value, 'amount')
            }
          ]
        },
        {
          width: '50',
          isHidden: hasNoMerchantOnCreate && !isFloomDiscount,
          fields: [
            {
              key: 'minPurchaseAmountLabel',
              fieldType: 'label',
              copy: 'Min purchase amount',
              subCopy: 'Order total excluding shipping',
              validationError: undefined
            },
            {
              key: 'minPurchaseAmountField',
              fieldType: 'textInput',
              min: 0,
              step: '0.01',
              prefix: this.renderMerchantCurrencySymbol(),
              value: this.props.discountCreateEditStore!.formValues.minBasketValue,
              validationError: this.findError('minBasketValue'),
              onChange: (e): void => this.handleChange(e.target.value, 'minBasketValue')
            }
          ]
        },
        {
          width: '100',
          isHidden: (hasNoMerchantOnCreate && !isFloomDiscount) || isFreeShippingSelected,
          fields: [
            {
              key: 'appliedToLabel',
              fieldType: 'label',
              copy: 'Applies to',
              subCopy: `This discount will apply to ${this.appliesToSubCopy()}, excluding add-ons and shipping fees`,
              validationError: undefined
            },
            {
              key: 'appliedToField',
              fieldType: 'radioList',
              itemType: 'default',
              itemTitleField: 'title',
              itemValueField: 'value',
              orientation: 'vertical',
              items: Constants.DISCOUNT_APPLICATIONS,
              selectedItem: this.props.discountCreateEditStore!.formValues.appliesTo,
              validationError: this.findError('appliesTo'),
              onChange: (planId: string): void => this.handleChange(planId, 'appliesTo')
            }
          ]
        },
        {
          width: '100',
          isHidden: (hasNoMerchantOnCreate && !isFloomDiscount) || isFreeShippingSelected || this.props.discountCreateEditStore!.formValues.appliesTo !== DiscountApplication.ProductTypes,
          fields: [
            {
              key: 'productTypesLabel',
              fieldType: 'label',
              copy: 'Select product types',
              subCopy: 'This discount will work on any product that is also linked to your selections below',
              validationError: undefined
            },
            {
              key: 'productTypesField',
              fieldType: 'tagSelection',
              itemCopySingular: 'product type',
              itemCopyPlural: 'product types',
              isLoading: this.props.discountCreateEditStore!.isLoadingMetadata,
              totalItemsCount: this.props.discountCreateEditStore!.productTypes.length,
              onRemove: (id): void => {
                this.props.discountCreateEditStore!.removeProductMetadataID(id, 'productTypes');
                this.makeItDirty();
              },
              onAdd: (): void => this.triggerProductTypeModal(this.buildProductTypeModalData()),
              validationError: this.findError('productTypes'),
              items: this.props.discountCreateEditStore!.productTypes.map(type => ({
                title: type.title,
                id: type.id,
                isSelected: this.isProductTypeSelected(type)
              })).filter(item => item.isSelected)
            }
          ]
        },
        {
          width: '100',
          isHidden: (hasNoMerchantOnCreate && !isFloomDiscount) || isFreeShippingSelected || this.props.discountCreateEditStore!.formValues.appliesTo !== DiscountApplication.ProductCategories,
          fields: [
            {
              key: 'productCategoriesLabel',
              fieldType: 'label',
              copy: 'Select product occasions',
              subCopy: 'This discount will work on any product that is also linked to your selections below',
              validationError: undefined
            },
            {
              key: 'productCategoriesField',
              fieldType: 'tagSelection',
              itemCopySingular: 'product occasion',
              itemCopyPlural: 'product occasions',
              isLoading: this.props.discountCreateEditStore!.isLoadingMetadata,
              totalItemsCount: this.props.discountCreateEditStore!.productCategories.length,
              onRemove: (id): void => {
                this.props.discountCreateEditStore!.removeProductMetadataID(id, 'productCategories');
                this.makeItDirty();
              },
              onAdd: (): void => this.triggerProductTypeModal(this.buildProductCategoryModalData()),
              validationError: this.findError('productCategories'),
              items: this.props.discountCreateEditStore!.productCategories.map(category => ({
                title: category.title,
                id: category.id,
                isSelected: this.isProductCategorySelected(category)
              })).filter(item => item.isSelected)
            }
          ]
        },
        {
          width: '100',
          isHidden: (hasNoMerchantOnCreate && !isFloomDiscount) || isFreeShippingSelected || this.props.discountCreateEditStore!.formValues.appliesTo !== DiscountApplication.Products,
          fields: [
            {
              key: 'selectedProductsLabel',
              fieldType: 'label',
              copy: 'Selected products',
              subCopy: 'This discount will only work on the custom website products you have selected below.',
              validationError: undefined
            },
            {
              key: 'selectedProductsField',
              fieldType: 'itemSelector',
              onAsyncSearch: async value => new Promise(async resolve => {
                this.setState({
                  productSearchValue: value
                }, async () => {
                  await this.fetchProducts();
                  resolve();
                });
              }),
              onClose: () => {
                if (!!this.state.productSearchValue.length) {
                  this.setState({
                    productSearchValue: ''
                  }, () => {
                    this.fetchProducts();
                  });
                }
              },
              items: this.props.discountCreateEditStore!.selectableProducts.map(product => ({
                title: product.title,
                id: product.id,
                details: product.categories?.map((category: any) => category.title).join(', ') || '',
                altTitle: product.title,
                mediaSource: product?.variations?.[0]?.media?.[0]?.src,
                selected: this.props.discountCreateEditStore!.formValues.selectedProducts.some(selectedProduct => selectedProduct.id === product.id)
              })),
              hasSearch: true,
              isMultiSelect: true,
              selectedItemIds: this.props.discountCreateEditStore!.formValues.selectedProducts.map(product => product.id),
              onItemRemove: (id): void => {
                this.props.discountCreateEditStore!.removeProductMetadataID(id, 'selectedProducts');
                this.makeItDirty();
              },
              setSelectedItemIds: (ids): void => {
                this.props.discountCreateEditStore!.applyProductMetadataIDs(ids, 'selectedProducts', 'selectableProducts');
                this.makeItDirty();
              },
              validationError: this.findError('selectedProducts'),
              modalHeadingCopy: 'Select some products',
              addButtonCopy: 'Select product(s)',
              noResultsHeading: 'We couldn\'t find any products',
              noResultsCopy: (
                <Fragment>
                  Try heading to your
                  {' '}
                  <Link
                    to={NavPages.ProductsList}
                    css={{
                      fontWeight: 'bold',
                      textDecoration: 'underline'
                    }}
                  >
                    product list
                  </Link>
                  {' '}
                  to make sure you have some custom website products created!
                </Fragment>
              ),
              isLoading: this.props.discountCreateEditStore!.isLoadingProducts
            }
          ]
        },
        {
          width: '100',
          isHidden: (hasNoMerchantOnCreate && !isFloomDiscount) || isFreeShippingSelected || isPercentageSelected,
          fields: [
            {
              key: 'oncePerOrderField',
              fieldType: 'singleCheckbox',
              label: 'Only apply this discount once per order',
              labelCopy: `If unselected, ${this.renderDiscountAmount()} will be taken off each eligible product in the basket`,
              isSelected: this.props.discountCreateEditStore!.formValues.oncePerOrder,
              isDisabled: false,
              validationError: this.findError('oncePerOrder'),
              handleSelect: (): void => this.handleChange(!this.props.discountCreateEditStore!.formValues.oncePerOrder, 'oncePerOrder')
            }
          ]
        }
      ]
    };
  };

  private renderUnfoundDiscount = (): ReactNode => (
    <NoResultsGeneric
      icon="leaf-no-results"
      heading="Oops, unable to find this discount at the moment"
      copy={(
        <Fragment>
          Head back to
          {' '}
          <Link
            css={{ fontWeight: 'bold', textDecoration: 'underline' }}
            to={NavPages.Discounts}
          >
            all discounts
          </Link>
        </Fragment>
      )}
    />
  );

  render(): ReactNode {
    const isLoadingOnEdit = this.isLoadingOnEdit();

    return (
      <Fragment>
        <FormHeader
          title={`${this.switchCopy('Create a', 'Edit')} discount`}
          buttonCopy="Is active?"
          actionType="toggle"
          hasAction={true}
          isActive={this.props.discountCreateEditStore!.formValues.isActive}
          isActionDisabled={isLoadingOnEdit}
          onAction={this.handleActiveStateSelect}
        />
        <Container maxWidth="700px">
          <Box m="100px 0">
            <WithLoading
              loaderSize="small"
              marginTop="30px"
              hasNoResults={this.cannotFindDiscountToEdit()}
              isLoading={isLoadingOnEdit}
              renderNoResults={this.renderUnfoundDiscount}
            >
              <FormBuilder
                config={this.buildForm()}
                notification={{
                  hasNotification: !!this.state.errorNotification.length,
                  notificationProps: {
                    copy: this.state.errorNotification,
                    type: NotificationType.Error
                  }
                }}
              />
            </WithLoading>
            <FormFooter>
              <Container maxWidth="700px">
                <Flex
                  justifyContent="space-between"
                  width="100%"
                >
                  <Box />
                  <Box
                    as="button"
                    onClick={this.handleSubmit}
                    disabled={this.state.isPosting || isLoadingOnEdit || !this.state.isDirty}
                  >
                    <Button
                      size="normal"
                      appearance="primary"
                      copy={`${this.switchCopy('Create', 'Update')} discount`}
                      isLoading={this.state.isPosting}
                      isDisabled={isLoadingOnEdit || !this.state.isDirty}
                    />
                  </Box>
                </Flex>
              </Container>
            </FormFooter>
          </Box>
        </Container>
      </Fragment>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  discountsStore: stores.discountsStore,
  discountCreateEditStore: stores.discountCreateEditStore,
  merchantStore: stores.merchantStore,
  modalStore: stores.modalStore,
  userStore: stores.userStore,
  toasterStore: stores.toasterStore
}))(observer(DiscountCreateEditLayout));
